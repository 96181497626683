var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.phrases)?_c('div',{staticClass:"position-table"},[_c('md-table',{scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{staticClass:"cursor-pointer"},[_c('md-table-cell',{attrs:{"md-label":"Id"}},[_c('span',{staticClass:"text ws-nowrap"},[_vm._v(_vm._s(item.id))])]),_c('md-table-cell',{attrs:{"md-label":"Наименование"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(item.timeName))])]),_c('md-table-cell',{attrs:{"md-label":"с"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(item.till))])]),_c('md-table-cell',{attrs:{"md-label":"до"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(item.from))])]),_c('md-table-cell',[_c('div',{staticClass:"edit-and-delete-block"},[_c('md-button',{staticClass:"md-just-icon md-simple md-primary"},[_c('md-icon',[_vm._v("edit")]),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Редактировать")])],1),_c('md-button',{staticClass:"md-just-icon md-simple md-primary",on:{"click":function($event){return _vm.openModal({
                  modalName: 'DeleteConfirm',
                  modalData: ("бан на '" + (item.timeName) + "'"),
                })}}},[_c('md-icon',[_vm._v("delete")]),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v("Удалить")])],1)],1)])],1)}}],null,false,1193392370),model:{value:(_vm.phrases),callback:function ($$v) {_vm.phrases=$$v},expression:"phrases"}}),_c('Pagination',{attrs:{"current-page":_vm.pagination.current_page,"last-page":_vm.pagination.last_page}}),_c('div',{staticClass:"d-flex justify-end"},[_c('md-button',{staticClass:"md-success",on:{"click":function($event){return _vm.openModal({
            modalName: 'EditBanTime',
          })}}},[_vm._v("Добавить период")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }