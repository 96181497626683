<template>
  <BanTime />
</template>

<script>
import BanTime from "@/components/Tables/BanTime.vue";

export default {
  components: { BanTime },
};
</script>
